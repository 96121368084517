import * as R from 'ramda'
import {graphql} from 'gatsby'
import loadable from '@loadable/component'
import React from 'react'

import {ethicsCompliancePropTypes} from 'helpers/propTypes'
import ContentSection from 'components/UI/ContentSection'
import Hero from 'components/UI/Hero'
import Section from 'components/UI/Section'
import SEO from 'components/seo'
import TextBox from 'components/AboutUs/Ethics'

const Ethics = ({pageContext, data}) => {
  const countryData = R.path(['contentfulCountry'], data)
  const Decoration = loadable(() => import('components/UI/Decoration'))
  const Decorations = loadable(() => import('components/UI/Decorations'))

  const {
    metaTitle,
    metaDescription,
    pageTitle,
    shortDescription: {shortDescription},
    surtitle,
    imageOrVideo,
    contentSections1,
    contentSections2,
  } = R.pathOr(null, ['allContentfulEthicsCompliance', 'nodes', 0], data)

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <div className="decorationWrapper">
        <Decorations>
          <Decoration webSiteName="keyrus" color="blue" top={52} />
          <Decoration webSiteName="keyrus" color="orange" right={0} top={60} />
          <Decoration webSiteName="keyrus" color="red" right={0} top={550} />
          <Decoration webSiteName="kls" svg="left" left={-80} top={0} />
          <Decoration webSiteName="kls" svg="right" right={0} top={34} />
        </Decorations>
        <Section hasPaddingTop={false} hasPaddingBottom={false}>
          <Hero
            hat={surtitle}
            title={pageTitle}
            description={shortDescription}
            media={imageOrVideo}
            titleType="title"
          />
        </Section>{' '}
        <TextBox contents={contentSections1} />
        <Section hasPaddingTop={false}>
          {R.map(
            content => (
              <ContentSection
                showSection={content.showContentSection}
                callToAction={content.callToAction}
                title={content.title}
                variation={content.variation}
                description={content.description}
                image={content.image}
                isOpenInNewWindow={content.isOpenInNewWindow}
                link={content.link}
                fileToDownload={content.fileToDownload}
                typeOfLink={content.typeOfLink}
                pageContext={pageContext}
                countryData={countryData}
              />
            ),
            contentSections2,
          )}
        </Section>
      </div>
    </>
  )
}

Ethics.propTypes = ethicsCompliancePropTypes

Ethics.defaultProps = {
  data: {},
  pageContext: {},
}

export const pageQuery = graphql`
  query templateEthics(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    allContentfulEthicsCompliance(
      filter: {node_locale: {eq: $nodeLocale}, slug: {regex: $entityRegEx}}
    ) {
      nodes {
        contentSections1 {
          showContentSection
          image {
            file {
              url
              contentType
            }
          }
          title
          variation
          description {
            raw
          }
        }
        contentSections2 {
          showContentSection
          fileToDownload {
            file {
              url
              fileName
              contentType
            }
          }
          description {
            raw
          }
          image {
            file {
              url
              contentType
            }
          }
          title
          variation
          callToAction
          isOpenInNewWindow
          link
          typeOfLink
        }
        imageOrVideo {
          file {
            url
            contentType
          }
        }
        metaDescription {
          metaDescription
        }
        metaTitle
        pageTitle
        shortDescription {
          shortDescription
        }
        surtitle
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`

export default Ethics
