import * as R from 'ramda'
import React from 'react'

import {ethicsTextBoxPropTypes} from 'helpers/propTypes'
import ContentSection from 'components/UI/ContentSection'
import Section from 'components/UI/Section'

import useStyles from './styles'

const TextBox = ({contents}) => {
  const classes = useStyles()
  const showSection = R.path([0, 'showContentSection'], contents)

  return (
    <>
      {showSection && (
        <Section hasPaddingTop={false}>
          <div className={classes.container}>
            {R.map(
              content => (
                <ContentSection
                  showSection={content.showContentSection}
                  title={content.title}
                  variation={content.variation}
                  description={content.description}
                  image={content.image}
                  isLogo
                />
              ),
              contents,
            )}
          </div>
        </Section>
      )}
    </>
  )
}

TextBox.propTypes = ethicsTextBoxPropTypes

TextBox.defaultProps = {}

export default TextBox
